// $(".rowImage").click(function (e) { 
//     e.preventDefault();
//     console.log('QWEYQUIWYIUQWEYUI');
//     // console.log($(this).attr("src"));
// });

// (function hello() {
//     alert('Hello!!!');
// })()

// $(".js-example-tags").select2({
//     tags: true
//   })